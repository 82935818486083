<template>
  <div>
    <ListViewWithDataHandler :panel="panel" :templateList="templateList" :propsParam="{ any: companyId }">
      <div slot="content-buttons">
        <Button _key="btnUserCreate" title="Usuário" classIcon="fas fa-plus-circle" type="primary" size="small"
          eventName="userCreate" :clicked="create" />
      </div>
    </ListViewWithDataHandler>
  </div>
</template>

<script>
import ListViewWithDataHandler from "@nixweb/nixloc-ui/src/component/template/ListViewWithDataHandler.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "UserListView",
  components: {
    ListViewWithDataHandler,
    Button,
  },
  data() {
    return {
      companyId: this.$route.params.id,
      panel: {
        module: "Adm",
        title: "Usuário",
        showFilter: true,
        showSearch: true,
        showButtons: true,
      },
      templateList: {
        urlGetApi: "/api/v1/support/user/get-all",
        showChecks: false,
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            routeName: "userSupportUpdate",
          },
          {
            field: "typeName",
            title: "",
            type: "class",
            fieldComparison: "typeName",
            classCssBody: [
              {
                classCss: "badge badge-info text-center",
                fieldComparison: "Adm",
              },
              {
                classCss: "badge badge-warning text-center",
                fieldComparison: "Padrão",
              },
            ],
          },
          {
            field: "userName",
            title: "Login",
            type: "text",
            iconSearch: false,
          },
          {
            field: "email",
            title: "E-mail",
            type: "text",
            iconSearch: false,
            classCssBody: "text-center",
            classCssTitle: "text-center",
          },
          {
            field: "isConfirmedEmail",
            title: "E-mail Confirmado",
            type: "text",
            iconSearch: false,
            classCssBody: "text-center",
            classCssTitle: "text-center",
          },
          {
            field: "mobile",
            title: "Celular",
            type: "text",
            iconSearch: false,
            classCssBody: "text-center",
            classCssTitle: "text-center",
          },
          {
            field: "isConfirmedMobile",
            title: "Celular Confirmado",
            type: "text",
            iconSearch: false,
            classCssBody: "text-center",
            classCssTitle: "text-center",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "userCreate",
      });
      this.removeLoading(["btnUserCreate"]);
    },
  },
};
</script>
