<template>
  <div>
    <div v-for="rule in rules" :key="rule.id">
      <div class="rule">
        <Molded>
          <Toggle :title="rule.nameBr" :color="'#577696'" :params="rule" :disabled="disabled" v-model="rule.hasRule"
            :changed="changed" />
        </Molded>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@nixweb/nixloc-ui/src/component/forms/Toggle";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapActions } from "vuex";

export default {
  name: "Rule",
  components: { Toggle, Alert, Molded },
  props: {
    rules: Array,
    companyId: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    urlAdd: String,
    urlRemove: String,
  },
  methods: {
    ...mapActions("generic", ["postApi", "deleteApi"]),
    changed(rule) {
      let ruleCompany = { ...rule, ...{ companyId: this.companyId } };
      if (rule.hasRule) this.add(ruleCompany);
      if (!rule.hasRule) this.remove(ruleCompany);
    },
    add(rule) {
      let params = { url: this.urlAdd, obj: rule };
      this.postApi(params).then((response) => { });
    },
    remove(rule) {
      let url = `${this.urlRemove}?companyId=${this.companyId}&ruleId=${rule.ruleId}`;
      let params = { url: url, obj: rule };
      this.deleteApi(params).then((response) => {
        if (response.success)
          this.$toasted.show("Removido com success", {
            type: "success",
          });
      });
    },
  },
};
</script>
<style scoped>
.rule {
  margin-bottom: 10px;
}
</style>