<template>
  <div>
    <Person :formName="formName" v-model="company.personResponse" />
    <br />
    <Contact :formName="formName" v-model="company.contactResponse" />
    <br />
    <Address :formName="formName" v-model="company.addressResponse" />
  </div>
</template>

<script>
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";
import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Address from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";

export default {
  name: "Company",
  props: {
    companyResponse: Object,
    value: Object,
  },
  components: {
    Contact,
    Person,
    Address,
  },
  data() {
    return {
      formName: "companyUpdate",
      company: {},
    };
  },
  created() {
    this.company = this.companyResponse;
  },
  computed: {},
  watch: {
    company: {
      handler(company) {
        this.$emit("input", company);
      },
      deep: true,
    },
  },
};
</script>
<style scoped></style>
