<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3">
        <InputNumber title="Usuário" field="user" :formName="formName" :required="true" :maxLength="50"
          v-model="plan.numberOfUsers" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3">
        <InputNumber title="Documento" field="document" :formName="formName" :required="true" :maxLength="50"
          v-model="plan.numberOfDocuments" />
      </b-col>
      <b-col xs="12" sm="12" md="12" lg="3" xl="3">
        <InputNumber title="Open Finance" field="document" :formName="formName" :required="true" :maxLength="50"
          v-model="plan.numberOfOpenFinance" />
      </b-col>
    </b-row>
    <br />
  </div>
</template>

<script>
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";

export default {
  name: "Plan",
  props: {
    planResponse: Object,
    value: Object,
  },
  components: {
    InputNumber,
  },
  data() {
    return {
      formName: "plan",
      plan: {},
    };
  },
  created() {
    this.plan = this.planResponse;
  },
  watch: {
    plan: {
      handler(plan) {
        this.$emit("input", plan);
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.icon-success {
  color: green;
}

.icon-warning {
  color: #f6ba45;
}
</style>