<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-buttons">
        <Button v-if="hasRule('remoteAccess')" _key="accessCompanyAccount" title="Acessar Conta"
          classIcon="fas fa-sign-in-alt" type="primary" size="small" eventName="accessCompanyAccount" />
      </div>
      <div slot="content-main">
        <CompanyUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>
import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import CompanyUpdate from "../../../components/modules/adm/support/CompanyUpdate.vue";

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "CompanyUpdateView",
  components: {
    Button,
    Molded,
    ViewTemplateWithSalveCancel,
    CompanyUpdate,
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Administração",
        title: "Usuário",
        formName: "userCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: true,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    create() {
      this.$router.push({
        name: "usuarioAdicionar",
      });
      this.removeLoading(["createUser"]);
    },
  },
};
</script>
